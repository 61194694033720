import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import {
  BreadCrumb,
  Background,
  Container,
  Section,
  SectionPageTitle,
  SectionTitle,
  Flex,
  FlexTwoCol,
  FlexVwCol
} from "../components/SectionTags"
import FreeDeliver from "../components/Icons/FreeDeliver"
import WarrantyIcon from "../components/Icons/WarrantyIcon"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import RepairContactForm from "../components/RepairContactForm"

import HeroBg from "../components/Image/IndustryHeroBg"

const ProductTypeWrap = styled.div`
  margin-bottom: 30px;
`
const ProductType = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  & span {
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    width: fit-content;
    font-size:20px;
    margin-right: 20px;
  }
  & strong {    
    color: #000;
    margin-bottom:0;
  }
`
const ProductMedia = styled.div`
  text-align:left;
  max-width: 300px;
  width: 100%;
  height: auto;
  img {
    width: 100%;
    height: 100%;
  }
`

const UspIcon = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 70px;
  text-align: center;
  background: #dfeefb;
  margin-right: 20px;
  & svg {
    margin-top: 50%;
    transform: translateY(-50%);
  }
`

const Description = styled.p`
  margin: 20px 0;
`

class ProductTemplate extends React.Component {
  
  render() {
    const pageData = this.props.data.internalProducts
    const temp = pageData.EquipmentType.replace(/[^\w\s]+/gi, " ").replace(
      /  +/g,
      " "
    )
    const typeUrl = temp.split(" ").join("-").toLowerCase() + "-repair"
    const productImage = this.props.pageContext.image 
    return (
      <Layout>
        <Seo title={pageData.name} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section bgColor="#EDF8FF" xpt="120px" xpb="60px" mpt="120px" mpb="60px" pt="90px" pb="30px">
          <Background width="65%" left="0"><HeroBg /></Background>
          <Container maxWidth="1120px">
              <BreadCrumb>
                <Link to="/"><span>Home</span></Link>
                <Link to={`/${typeUrl}`}><span>{pageData.EquipmentType} Repair</span></Link>
                <span>{pageData.Manufacturer}</span>
                {pageData.Item}
              </BreadCrumb>
              <Flex alignItem="center" justyfyContent="space-between">
                <FlexTwoCol  flexWidth="66.666667%" flexMWidth="66.666667%">
                  <SectionPageTitle className="h2">Part Number: {pageData.MFGPart_}</SectionPageTitle>
                  <ProductTypeWrap>
                    {/* <ProductType>
                      <span>Equipment Type : </span>
                      <strong className="h4">{pageData.EquipmentType}</strong>
                    </ProductType> */}
                    <ProductType>
                      <span>Manufacturer : </span>
                      <strong className="h4">{pageData.Manufacturer}</strong>
                    </ProductType>
                    <ProductType>
                      <span>Item Description:</span><span>{pageData.Item}</span>
                    </ProductType>
                    {/* <ProductType>
                      <span>Service Type : </span>
                      <strong className="h4">{pageData.EquipmentType}</strong>
                    </ProductType>
                    <ProductType>
                      <span>Industry : </span>
                      <strong className="h4">{pageData.EquipmentType}</strong>
                    </ProductType> */}
                  </ProductTypeWrap>
                  {/* <SectionPageTitle>{pageData.Item}</SectionPageTitle> */}
                </FlexTwoCol>
                <FlexVwCol>
                  <ProductMedia>
                    <img src={productImage} alt={pageData.Item} />
                  </ProductMedia>
                </FlexVwCol>
              </Flex>
          </Container>
        </Section>
        <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="30px" pb="30px">
          <Container maxWidth="1120px">
            <SectionTitle>Key Benefits</SectionTitle>
            <Flex>
              <FlexTwoCol>
                <Flex alignItem="center">
                  <UspIcon>
                    <FreeDeliver />
                  </UspIcon>
                  <h4>Free Pick-up and Delivery</h4>
                </Flex>
                <Description>AES provide Free Pickup & Delivery for the following areas: North Carolina, South Carolina, Tennessee, Kentucky, Virginia, West Virginia, and Georgia. If you are in one of these areas, you may qualify for Free Pickup & Delivery services. Complete the below form to request a pickup of your equipment.</Description>
              </FlexTwoCol>
              <FlexTwoCol>
                <Flex alignItem="center">
                  <UspIcon>
                    <WarrantyIcon />
                  </UspIcon>
                  <h4>2-Year Warranty</h4>
                </Flex>
                <Description>AES is dedicated to quality service and we stand behind our work with our 2-year warranty on every repair. If your equipment doesn’t function perfectly when you receive it, you can return your item and we will cover any additional repair costs for free.</Description>
              </FlexTwoCol>
            </Flex>
          </Container>
        </Section>
        <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="30px" pb="30px">
          <Container maxWidth="1120px">
            <RepairContactForm />
          </Container>
        </Section>
        <EmergencyEstimateCTA />
      </Layout>
    )
  }
}

export default ProductTemplate


export const pageQuery = graphql`
  query productPageQuery($id: String!) {
    internalProducts(id: { eq: $id }) {
      Item
      RepairPrice
      EquipmentType
      Manufacturer
      ServiceType
      Industry
      MFGPart_
    }
  }
`
