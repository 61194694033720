import React from "react"

const FreeDeliver = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40.6" height="26.3" viewBox="0 0 40.6 26.3">
    <g id="Group_6208" data-name="Group 6208" transform="translate(0.3)">
      <g id="Pickup_Truck" data-name="Pickup Truck" transform="translate(0 1.539)">
        <path id="Path_19108" data-name="Path 19108" d="M37.333,22.012H35.01l-5.67-7.665A3.381,3.381,0,0,0,26.627,13H20.667a1.311,1.311,0,0,0-1.333,1.287v7.725H2.667A.656.656,0,0,0,2,22.656v9.012A1.967,1.967,0,0,0,4,33.6H6.053a4.612,4.612,0,0,0,4.613,3.861A4.612,4.612,0,0,0,15.28,33.6H28.72a4.687,4.687,0,0,0,9.227,0H40a1.967,1.967,0,0,0,2-1.931v-5.15A4.594,4.594,0,0,0,37.333,22.012Zm3.333,4.506v.644H38V25.875h2.6a3.112,3.112,0,0,1,.067.644ZM28.255,15.1l5.116,6.916h-8.7V14.287h1.961A2.03,2.03,0,0,1,28.255,15.1ZM3.333,31.669V25.875h2V24.587h-2V23.3H20a.656.656,0,0,0,.667-.644V14.287h2.667V32.312H15.28a4.612,4.612,0,0,0-4.613-3.861,4.612,4.612,0,0,0-4.613,3.861H4A.656.656,0,0,1,3.333,31.669Zm7.333,4.506a3.221,3.221,0,1,1,0-6.437,3.221,3.221,0,1,1,0,6.437Zm22.667,0a3.221,3.221,0,1,1,3.333-3.219,3.278,3.278,0,0,1-3.333,3.219ZM40,32.312H37.947a4.687,4.687,0,0,0-9.227,0H24.667V23.3H37.333a3.358,3.358,0,0,1,2.649,1.287H38a1.311,1.311,0,0,0-1.333,1.287v1.287A1.311,1.311,0,0,0,38,28.45h2.667v1.287h-2v1.287h2v.644A.656.656,0,0,1,40,32.312Z" transform="translate(-2 -13)" fill="#0059b2" stroke="#0059b2" stroke-width="0.6"/>
        <path id="Path_19109" data-name="Path 19109" d="M38,31h2.667v1.287H38Z" transform="translate(-14 -19.413)" fill="#0059b2" stroke="#0059b2" stroke-width="0.6"/>
        <path id="Path_19110" data-name="Path 19110" d="M14,43h1.333v1.287H14Z" transform="translate(-6 -23.688)" fill="#0059b2" stroke="#0059b2" stroke-width="0.6"/>
        <path id="Path_19111" data-name="Path 19111" d="M48,43h1.333v1.287H48Z" transform="translate(-17.333 -23.688)" fill="#0059b2" stroke="#0059b2" stroke-width="0.6"/>
      </g>
      <g id="tick_2_" data-name="tick (2)" transform="translate(2.056 0)">
        <g id="Group_5961" data-name="Group 5961" transform="translate(0 0)">
          <g id="Group_5960" data-name="Group 5960">
            <path id="Path_16800" data-name="Path 16800" d="M134.115,165.636a.872.872,0,0,0-1.233-.033l-5.57,5.282-2.657-2.727a.872.872,0,0,0-1.25,1.217l3.257,3.344a.873.873,0,0,0,1.226.024l6.194-5.874A.872.872,0,0,0,134.115,165.636Z" transform="translate(-123.158 -165.364)" fill="#0059b2"/>
          </g>
        </g>
      </g>
    </g>
  </svg>

)

export default FreeDeliver
